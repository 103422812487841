import React, { useEffect } from "react";
import "./about.css";
import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with your preferred options
  }, []);

  return (
    <section id="about" className="about" data-aos="fade-up">
      <h1 data-aos="fade-left">About me:</h1>
      <p>
        Hi, my name is Aman Kumar. I am a Fullstack web developer, UI designer,
        and Mobile developer. I have honed my skills in Web Development and have
        a core understanding of advanced UI design principles. Here are the
        major skills I possess:
      </p>
      <div className="exp" data-aos="fade-right">
        <div className="num">
          <h1>2+</h1>
        </div>
        <div className="text">
          <h3>
            Years of experience. Specialised in building apps, while ensuring a
            seamless web experience for end users.
          </h3>
        </div>
      </div>
    </section>
  );
};

export default About;
