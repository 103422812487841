import React, { useEffect } from 'react';
import './serv.css';
import ruler from '../../assets/img/ruler-pen-svgrepo-com.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Serv = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with your preferred options
  }, []);

  return (
    <section id='services' className='service'>
      <h2 data-aos="fade-up">The services I offer:</h2>
      <div className='rectangle'>
        <div className='box' data-aos="fade-left">
          <img src={ruler} alt="Fullstack Web Development" />
          <h3>Fullstack Web Development</h3>
          <p>I develop robust and scalable web applications using modern technologies, ensuring a seamless user experience from frontend to backend.</p>
        </div>

        <div className='box' data-aos="fade-right">
          <img src={ruler} alt="Backend API Development" />
          <h3>Backend API Development</h3>
          <p>I create efficient and secure APIs that serve as the backbone for web and mobile applications, enabling smooth data communication and integration.</p>
        </div>

        <div className='box' data-aos="fade-up">
          <img src={ruler} alt="Responsive Design for Multiple Devices" />
          <h3>Responsive Design for Multiple Devices</h3>
          <p>I design and implement responsive layouts that adapt to various screen sizes, ensuring an optimal viewing experience on desktops, tablets, and smartphones.</p>
        </div>

        <div className='box' data-aos="fade-down">
          <img src={ruler} alt="Hosting and Maintenance" />
          <h3>Hosting and Maintenance</h3>
          <p>I provide reliable hosting solutions and ongoing maintenance services to keep your web applications running smoothly and securely.</p>
        </div>

        <div className='box' data-aos="fade-left">
          <img src={ruler} alt="Mobile Application Development" />
          <h3>Mobile Application Development</h3>
          <p>I develop high-performance mobile applications for both Android and iOS platforms, focusing on user-friendly interfaces and seamless functionality.</p>
        </div>

        <div className='box' data-aos="fade-right">
          <img src={ruler} alt="Real-time Application Development" />
          <h3>Real-time Application Development</h3>
          <p>I build real-time applications using WebSocket and other technologies, enabling instant data updates and live interactions for a dynamic user experience.</p>
        </div>
      </div>
    </section>
  );
}

export default Serv;
