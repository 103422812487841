import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./project.css";
import ProjectImg from "../../assets/img/project-management-planning-development-message-box-notification-graphic.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const Projects = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section id="projects" className="pro">
      <h1 data-aos="fade-down">Featured Projects :</h1>
      <p data-aos="fade-down">
        I have worked on many projects over the course of being a Web Developer,
        here are a few of my live, real-world projects
      </p>
      <div className="Projects">
        <div className="project" data-aos="fade-up">
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            interval={2000}
            showStatus={false}  
            showThumbs={false}
          >
            <div>
            <img src={ProjectImg} alt="Project" />

            </div>
            <div>

            <img
              src="https://www.shutterstock.com/image-photo/project-manager-working-on-laptop-260nw-2175846471.jpg"
              alt=""
            />
            </div>
          </Carousel>
          <h3>Real-Time Chat Website</h3>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eum,
            voluptatem?
          </p>
          <div className="buttons">
            <button className="button1">View Live</button>
            <button className="button2"> Github repo</button>
          </div>
        </div>
        <div className="project" data-aos="fade-up">
        <Carousel
            autoPlay={true}
            infiniteLoop={true}
            interval={2000}
            showStatus={false}  
            showThumbs={false}
          >
            <div>
            <img src={ProjectImg} alt="Project" />

            </div>
            <div>

            <img
              src="https://www.shutterstock.com/image-photo/project-manager-working-on-laptop-260nw-2175846471.jpg"
              alt=""
            />
            </div>
          </Carousel>          <h3>Collbration Text Editor</h3>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eum,
            voluptatem?
          </p>
          <div className="buttons">
            <button className="button1">View Live</button>
            <button className="button2"> Github repo</button>
          </div>
        </div>
        <div className="project" data-aos="fade-up">
        <Carousel
            autoPlay={true}
            infiniteLoop={true}
            interval={2000}
            showStatus={false}  
            showThumbs={false}
          >
            <div>
            <img src={ProjectImg} alt="Project" />

            </div>
            <div>

            <img
              src="https://www.shutterstock.com/image-photo/project-manager-working-on-laptop-260nw-2175846471.jpg"
              alt=""
            />
            </div>
          </Carousel>          <h3>Quiz Website</h3>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eum,
            voluptatem?
          </p>
          <div className="buttons">
            <button className="button1">View Live</button>
            <button className="button2"> Github Repo</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
