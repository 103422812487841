import "./App.css";
import { useEffect } from "react";
import Navbar from "./components/navbar/Navbar";
import Hero from "./components/hero/Hero";
import Projects from "./components/projects/Projects";
import Skills from "./components/skills/Skills";
import About from "./components/about/About";
import Service from "./components/serv/Serv";
import Contact from "./components/contact/Contact";
import AOS from 'aos';
import 'aos/dist/aos.css';
function App() {

useEffect(() => {
  AOS.init({ duration: 1000 });
}, []);

  return (
    <>
      <Navbar />
      <Hero />
      <About />
      <Skills/>
      <Service/>
      <Projects/>
      <Contact/>
    </>
  );
}

export default App;
