import React, { useEffect } from "react";
import "./contact.css";
import AOS from "aos";
import "aos/dist/aos.css";

const ContactFormAndFooter = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with your preferred options
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Message sent successfully");
    e.target.reset();
  };

  return (
    <section id="contact" className="contact-footer-container">
      <div className="contact-section">
        <div className="contact-left" data-aos="fade-right">
          <h2>Connect with me:</h2>
          <p>Satisfied with me? Please contact me</p>
          <div className="social-icons">
            <a href="#">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="#">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#">
              <i className="fab fa-dribbble"></i>
            </a>
            <a href="#">
              <i className="fas fa-envelope"></i>
            </a>
          </div>
        </div>
        <div className="contact-right" data-aos="fade-left">
          <h2>Contact me, let's make magic together</h2>
          <form onSubmit={handleSubmit}>
            <input type="text" name="name" placeholder="Name:" required />
            <input type="email" name="email" placeholder="Email:" required />
            <textarea name="message" placeholder="Message:" required></textarea>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
      <footer>
        <div className="footer-content">
          <div className="footer-left">
            <a href="#">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="#">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#">
              <i className="fab fa-dribbble"></i>
            </a>
            <a href="#">
              <i className="fas fa-envelope"></i>
            </a>
          </div>
          <div className="footer-right">
            <p>&copy;Aman Kumar</p>
            <p>Fullstack Developer</p>
            <p>UI Designer</p>
            <p>Designed by @ernestechie</p>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default ContactFormAndFooter;
