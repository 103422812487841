import React, { useState } from "react";
import "./navbar.css";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const navLinks = [
    {
      id: 1,
      title: "Home",
      url: "#home",
    },
    {
      id: 2,
      title: "About",
      url: "#about",
    },
    {
      id: 3,
      title: "Skills",
      url: "#skills",
    },
    {
      id: 4,
      title: "Services",
      url: "#services",
    },
    {
      id: 5,
      title: "Projects",
      url: "#projects",
    },
    {
      id: 6,
      title: "Contact",
      url: "#contact",
    },
  ];

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="logo">
          <span>Aman Kumar</span>
        </div>
        <div
          className={`menu-icon ${showMenu ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <ul className={`nav-links ${showMenu ? "open" : ""}`}>
          {navLinks.map((link) => (
            <li key={link.id}>
              <a href={link.url}>{link.title}</a>
            </li>
          ))}
        </ul>
        <a href="https://drive.google.com/file/d/1aZY7dKUSf-UqYWR8v3mQXmJYPnYIMUwu/view?usp=drive_link" 
        target="_blank" rel="noreferrer"
        id="resumeButton">Resume</a>
      </div>
    </nav>
  );
};

export default Navbar;
