import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./skills.css";
import ruler from "../../assets/img/ruler-pen-svgrepo-com.svg";
import code from "../../assets/img/code.svg";
import android from "../../assets/img/android.svg";
import python from "../../assets/img/python-svg.svg";

const Skills = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section id='skills' className="skills">
      <div className="cards">
        <div className="card" data-aos="fade-up">
          <img src={ruler} alt="UI & UX Designing" />
          <h2>
             React.JS & Next.JS  <br />Development
          </h2>
        </div>
        <div className="card" data-aos="fade-up">
          <img src={code} alt="Web Development" />
          <h2>
            Node.JS & Nest.JS  <br />Development
          </h2>
        </div>
        <div className="card" data-aos="fade-up">
          <img src={android} alt="Android Development" />
          <h2>
           Tailwind & Responsive <br />Web Design
          </h2>
        </div>
        <div className="card" data-aos="fade-up">
          <img src={python} alt="Machine Learning" />
          <h2>
            AWS Cloud <br />Management 
          </h2>
        </div>
      </div>
    </section>
  );
};

export default Skills;
